export function initCompareBanner() {
  const compareProductsList = JSON.parse(localStorage.getItem('compareProducts')) || [];

  compareProductsList.forEach((product) => {
    const checkbox = document.querySelector(
      `.product-compare-checkbox[data-product-id="${product.productId}"]`,
    );
    if (checkbox) {
      checkbox.checked = true;
    }
  });

  if (compareProductsList.length > 0) {
    document.querySelector('.compare-banner__top-btn-compare-clear').addEventListener('click', () => {
      document.querySelectorAll(`.product-compare-checkbox:checked`).forEach((checkbox) => {
        checkbox.checked = false;
      });
    });
  }
}

function updateLocalStorage(productID) {
  let compareProductsList = JSON.parse(localStorage.getItem('compareProducts')) || [];
  const checkedCheckbox = document.querySelector(`.product-compare-checkbox[data-product-id="${productID}"]`);
  const productId = checkedCheckbox.getAttribute('data-product-id');
  const productUrl = checkedCheckbox.getAttribute('data-product-url').split('?')[0];

  if (!compareProductsList.some((product) => product.productId === productID)) {
    compareProductsList.push({ productId, productUrl });
  }

  localStorage.setItem('compareProducts', JSON.stringify(compareProductsList));
}

function removeFromLocalStorage(productID) {
  let compareProductsList = JSON.parse(localStorage.getItem('compareProducts'));
  compareProductsList = compareProductsList.filter((product) => product.productId !== productID);
  localStorage.setItem('compareProducts', JSON.stringify(compareProductsList));
  document.querySelector(`.product-compare-checkbox[data-product-id="${productID}"]`).checked = false;
}

function updateSelectedCount() {
  const compareProductsList = JSON.parse(localStorage.getItem('compareProducts')) || [];
  const selectedCount = document.querySelector('.compare-banner__top-counter');
  const compareButton = document.querySelector('.compare-banner__top-btn-compare');

  selectedCount.textContent = compareProductsList.length;
  if (compareProductsList.length >= 2) {
    compareButton.classList.remove('disabled');
  } else {
    compareButton.classList.add('disabled');
  }
}

function controlCheckboxLimit() {
  let compareProductsList = JSON.parse(localStorage.getItem('compareProducts')) || [];
  const uniqueProductIds = new Set(compareProductsList.map((product) => product.productId));
  const compareCheckboxes = document.querySelectorAll('.product-compare-checkbox');

  if (uniqueProductIds.size >= 4) {
    compareCheckboxes.forEach((checkbox) => {
      if (!checkbox.checked) {
        checkbox.classList.add('disabled');
        checkbox.setAttribute('disabled', '');
      }
    });
  } else {
    compareCheckboxes.forEach((checkbox) => {
      checkbox.classList.remove('disabled');
      checkbox.removeAttribute('disabled');
    });
  }
}

function clearDuplicates(checkbox) {
  const productId = checkbox.getAttribute('data-product-id');
  document
    .querySelectorAll(`.product-compare-checkbox[data-product-id="${productId}"]`)
    .forEach((checkbox2) => {
      checkbox2.checked = false;
    });
}

export function clickOnCheckbox(e) {
  const checkBox = e.target.closest('input[type="checkbox"]');

  if (checkBox) {
    const productID = checkBox.getAttribute('data-product-id');
    if (checkBox.checked) {
      updateLocalStorage(productID);
    } else {
      clearDuplicates(checkBox);
      removeFromLocalStorage(productID);
    }
    updateSelectedCount();
    controlCheckboxLimit();
    renderCompareDrawer();
    document.querySelector('.compare-banner').classList.add('open');
    document.querySelector('.compare-banner__top-btn-compare-clear').addEventListener('click', () => {
      document.querySelectorAll(`.product-compare-checkbox:checked`).forEach((checkbox) => {
        checkbox.checked = false;
      });
    });
  }
}

async function renderCompareDrawer() {
  const compareProducts = getSelectedProducts();
  const compareProductCount = compareProducts.length;
  const placeholdersNeeded = Math.max(0, 4 - compareProductCount);
  const compareBannerItems = document.querySelector('.compare-banner__product-items');
  compareBannerItems.innerHTML = '';

  if (compareProductCount > 0) {
    const compareDrawerProductsHtml = await getDrawerProducts(compareProducts);
    compareDrawerProductsHtml.forEach((html) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = JSON.parse(html)['compare-drawer-product'];
      const compareProductItem = tempDiv.querySelector('.compare-banner__product-item');
      compareBannerItems.appendChild(compareProductItem);
    });
  }

  for (let i = 0; i < placeholdersNeeded; i++) {
    const placeholder = document.createElement('div');
    placeholder.classList.add('compare-banner__product-item');
    placeholder.innerHTML =
      '<div class="compare-banner__product-item__empty-msg">Select product to compare</div>';
    compareBannerItems.appendChild(placeholder);
  }
}

function getSelectedProducts() {
  return JSON.parse(localStorage.getItem('compareProducts')) || [];
}

async function getDrawerProducts(compareProducts) {
  const tempHtml = {};
  await Promise.all(
    compareProducts.map(async (product) => {
      const response = await fetch(`${product.productUrl}?sections=compare-drawer-product`);
      const html = await response.text();
      tempHtml[product.productId] = html;
    }),
  );
  const returnHtml = [];
  compareProducts.forEach((product) => returnHtml.push(tempHtml[product.productId]));
  return returnHtml;
}
