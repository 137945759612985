import shopifyGenericDefaults from '../shopify-generic/config.js';

import { showModal, hideModal } from './quickViewDrawer.js';
import { clickOnCheckbox, initCompareBanner } from './compareDrawer.js';

window.Convermax.closeQuickView = () => hideModal();
window.Convermax.quickView = (e) => showModal(e);
window.Convermax.clickOnCheckbox = (e) => clickOnCheckbox(e);

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();
  initCompareBanner();
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  pagination: {
    edgeRange: 0,
    centralRange: 1,
    disableDelimeters: true,
  },
  defaultView: 'grid',
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchResult'].includes(w.name),
    ),
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      isAlwaysColumnLayout: true,
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
