
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon icon-search',
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('path', {
                'd': 'M16.9265 17.04L20.3996 20.4M19.2796 11.44C19.2796 15.7699 15.7695 19.28 11.4396 19.28C7.1097 19.28 3.59961 15.7699 3.59961 11.44C3.59961 7.11006 7.1097 3.59998 11.4396 3.59998C15.7695 3.59998 19.2796 7.11006 19.2796 11.44Z',
                'stroke': 'currentColor',
                'strokeWidth': '1.5',
                'strokeLinecap': 'round'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]