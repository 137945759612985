
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'grid__item scroll-trigger animate--slide-in' }, _createElement('div', { 'className': 'card-wrapper product-card-wrapper underline-links-hover' }, _createElement('div', { 'className': 'card card--card card--media color-scheme-1 gradient' }, _createElement('div', { 'className': 'card__inner ratio' }, _createElement('a', {
                    'href': this.url,
                    'className': 'media-link'
                }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'className': 'motion-reduce'
                }), this.image2 ? _createElement('img', {
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'className': 'motion-reduce',
                    'loading': 'lazy',
                    'key': '574'
                }) : null))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h6' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } })))))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h6' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '1523'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.price_varies ? _createElement('span', { 'key': '1658' }, 'From: ') : null, ' ', this.formatPrice(this.price), '\n                  ')) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '1800'
                }, _createElement('span', { 'className': 'price-item price-item--sale' }, ' ', this.formatPrice(this.price), ' '), _createElement('span', { 'className': 'price-item--last' }, _createElement('s', { 'className': 'price-item price-item--regular' }, ' ', this.formatPrice(this.compare_at_price), ' '))) : null))), _createElement('div', { 'className': 'product-compare' }, _createElement('label', { 'className': 'product-compare-info' }, _createElement('input', {
                    'onClick': e => window.Convermax.clickOnCheckbox(e),
                    'className': 'product-compare-checkbox',
                    'type': 'checkbox',
                    'data-product-id': this.id,
                    'data-product-url': this.url
                }), '\n              Add to compare\n            '))), _createElement('div', { 'className': 'quick-add no-js-hidden' }, _createElement('modal-opener', {}, _createElement('button', {
                    'onClick': e => window.Convermax.quickView(e),
                    'type': 'submit',
                    'name': 'add',
                    'className': 'quick-add__submit button button-2 button--full-width',
                    'aria-haspopup': 'dialog',
                    'aria-labelledby': 'quick-add-' + this.id + '-submit title--' + this.id,
                    'data-product-url': this.url,
                    'data-modal': '#PopupModal'
                }, _createElement('span', {}, ' Quick View '), _createElement('div', { 'className': 'loading__spinner hidden' }, [_createElement('svg', {
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'className': 'spinner',
                        'viewBox': '0 0 66 66',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'key': '30490'
                    }, _createElement('circle', {
                        'className': 'path',
                        'fill': 'none',
                        'strokeWidth': '6',
                        'cx': '33',
                        'cy': '33',
                        'r': '30'
                    }))]))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []